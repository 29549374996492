.prjc-column {
  flex-grow: 1;
  display: flex;
  position: relative;
  text-decoration: none;
}

.prjc-column:not(:last-child) {
  /* border-right: var(--prj-line-size) solid var(--color-2); */
}

.prjc-column:not(:last-child)::after {
  position: absolute;
  content: "";
  width: var(--prj-line-size);
  /* background-color: red; */
  background-color: var(--color-2);
  height: 100%;
  left: 100%;
  bottom: 0;
}

@media only screen and (max-width: 53.13em) {
  /* 850px */
  .prjc-column:not(:last-child)::after {
    background-color: transparent;
  }
  .prjc-column:not(:last-child) {
    border-bottom: var(--color-2);
  }
}

.prjc-column:first-child {
  /* border-left: 0.1rem solid var(--color-2); */
}

.prjc-column:last-child {
  /* border-right: 0.1rem solid var(--color-2); */
}

.prjc-column {
  padding: 1rem;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.prjc-column::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: transparent;
}

.mobile-bottom-line {
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: transparent;
}

@media only screen and (max-width: 53.13em) {
  /* 850px */
  div.mobile-bottom-line {
    background-color: var(--color-2);
  }
}

.prjc-column::before {
  position: absolute;
  top: 2rem;
  right: 1rem;
  font-weight: 700;
  font-family: var(--font-1);
  font-size: 10rem;
  color: transparent;
  -webkit-text-stroke: 0.006rem #ffffffa1;
}

@media only screen and (max-width: 43.75em) {
  /* 700px */
  .prjc-column::before {
    transform: scale(0.7);
    top: 0;
    right: 0;
  }
}

.prjc-column-bottom {
  display: flex;
  margin-top: 20rem;
}
@media only screen and (max-width: 66.88em) {
  /* 1070px */
  .prjc-column-bottom {
    margin-top: 15rem;
  }
}

.prjc-name-and-tools {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  /* background-color: red; */
}

.prjc-name {
  display: inline-block;
  margin-left: auto;
  position: relative;
  /* text-align: right; */

  background-image: linear-gradient(
    to top,
    var(--bottom) 0%,
    var(--bottom) 0%,
    var(--top) 0%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.prjc-description {
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 100%;
  color: var(--color-primary);
  -webkit-text-fill-color: var(--color-primary);
  text-transform: uppercase;
  margin-left: auto;
}

@media only screen and (max-width: 53.13em) {
  /* 850px */
  .prjc-name {
    text-align: left;
    margin-left: 0;
  }
  .prjc-description {
    margin-left: 0;
  }
}

.prjc-tools {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;
  /* gap: 1.5rem; */
}

.prjc-tool {
  vertical-align: middle;
  font-size: 1rem;
}

.prjc-tool {
  position: relative;
  display: flex;
  align-items: center;
}

.prjc-tool:not(:first-child) {
  margin-left: 2rem;
}

.prjc-tool:not(:first-child)::before {
  position: absolute;
  left: -1.2rem;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--color-primary);
}

/* Coulmn number */
[data-column-no="1"]::before {
  content: url("./../../../../public/assets/images/01.svg");
}
[data-column-no="2"]::before {
  content: url("./../../../../public/assets/images/02.svg");
}
[data-column-no="3"]::before {
  content: url("./../../../../public/assets/images/03.svg");
}
[data-column-no="4"]::before {
  content: url("./../../../../public/assets/images/04.svg");
}
[data-column-no="5"]::before {
  content: url("./../../../../public/assets/images/05.svg");
}
[data-column-no="6"]::before {
  content: url("./../../../../public/assets/images/06.svg");
}
[data-column-no="7"]::before {
  content: url("./../../../../public/assets/images/07.svg");
}

/* Coulmn number */
/* [data-column-no="1"] .prjc-name::after {
  content: "E-Learning platform";
}
[data-column-no="2"] .prjc-name::after {
  content: "Quick credit platform";
}
[data-column-no="3"] .prjc-name::after {
  content: "Task management template";
}
[data-column-no="4"] .prjc-name::after {
  content: "analytical template";
}
[data-column-no="5"] .prjc-name::after {
  content: "Qstore";
}
[data-column-no="6"] .prjc-name::after {
  content: "NodTransfer";
}
[data-column-no="7"] .prjc-name::after {
  content: "Crypto Exchange";
} */
