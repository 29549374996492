@keyframes bounce {
  0% {
    /* opacity: 0;
    visibility: hidden; */
    transform: translateY(-2rem);
  }
  50% {
    /* opacity: 0;
    visibility: hidden; */
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(-2rem);
  }
}

@keyframes MoveXAxis {
  0% {
    transform: translateX(-90%);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-90%);
  }
}

/* Classes */
.animate-bounce {
  animation: bounce 3s linear 0.3s infinite;
  /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
