.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-aicenter {
  display: flex;
  align-items: center;
}

.flex-row-jcbetween-aicenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Flex items */
