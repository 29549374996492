.tn {
  padding: 6.5rem 0 3.5rem;
}

.tn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 36.56em) {
  /* 585px */
  .tn {
    padding: 3.5rem 0;
  }

  .tn-wrapper {
    flex-direction: column;
    gap: 4rem;
    align-items: end;
  }
}

.tn-left {
  display: flex;
  align-items: center;
  gap: 25rem;
}
@media only screen and (max-width: 46.81em) {
  /* 749px */
  .tn-left {
    gap: 10rem;
  }
}
@media only screen and (max-width: 36.56em) {
  /* 585px */
  .tn-left {
    width: 100%;
    justify-content: space-between;
  }
  .tn-wrapper {
    width: 100%;
  }
}

.tn-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tn-stack-ele {
  display: flex;
  flex-direction: column;
}

.tn-text {
  font-family: var(--font-2);
}

.tn-contacts-box {
  position: relative;
}

.tn-contact-list {
  position: absolute;
  top: 120%;
  right: 0;
  right: -10rem;
  height: 5rem;
  width: fit-content;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 4rem;
  overflow: hidden;
}
@media only screen and (max-width: 29.68em) {
  /* 475px */
  .tn-contact-list {
    gap: 1.5rem;
  }
}

.tn-contact-option {
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  align-self: center;
  text-decoration: none;
  visibility: hidden;
}
