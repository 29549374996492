body {
  font-family: var(--font-1);
  font-size: 1.6rem;
  line-height: 1.7rem;
  font-weight: 500;
}

/* font-family: 'Jost', sans-serif; */
/* font-family: 'Josefin Slab', serif; */

/* Headings */
.hd-1 {
  font-family: "Josefin Slab";
  font-weight: 500;
  font-size: 1.7rem; /* 2rem -> 20px -> 1.46413vw */
  line-height: 1.7rem;
  color: var(--color-primary);
}
.hd-2 {
  font-size: 2rem;
}
.hd-3 {
  font-weight: 700;
  /* font-size: 18.5rem; */
  font-size: clamp(5.5rem, 11vw, 18.5rem);
  line-height: 100%;
  color: var(--color-primary);
}
.hd-4 {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 100%;
  color: var(--color-primary);
  /* letter-spacing: 0.225em; */
  /* border: 2px solid #ffffff; */
}
.hd-5 {
  font-weight: 700;
  font-size: 8rem;
  font-size: clamp(3.2rem, 4.5vw, 8rem);
  line-height: 100%;
  color: var(--color-primary);
}
.hd-6 {
  font-weight: 700;
  font-size: 3.5rem;
  font-size: clamp(2rem, 4.5vw, 2.5rem);
  line-height: 100%;
  color: var(--color-primary);
}

/* Headings */
/* Paragraphs */
.prg-1 {
  font-style: normal;
  font-weight: 300;
  font-size: 2.2rem;
  font-size: clamp(1.6rem, 3.5vw, 2.2rem);
  line-height: 140%;
  color: var(--color-white);
}
.prg-2 {
  font-weight: 300;
  font-size: 2.5rem;
  font-size: clamp(1.8rem, 4.5vw, 2.5rem);
  line-height: 140%;
  color: var(--color-primary);
}
.prg-3 {
  font-weight: 300;
  font-size: 2rem;
  font-size: clamp(1.5rem, 4.5vw, 2rem);
  line-height: 140%;
  color: var(--color-primary);
}
.prg-4 {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 140%;
  color: var(--color-white);
}
.prg-5 {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 140%;
  color: var(--color-primary);
}
.prg-6 {
  font-weight: 300;
  font-size: 1rem;
  line-height: 130%;
  color: var(--color-primary);
}
/* Paragraphs */

/* Custom */
.heading {
  visibility: hidden;
  /* padding: 0; */
  /* border: 1px solid red; */
  display: flex;
  overflow: hidden;
  /* padding-bottom: 0.5vw; */
}
