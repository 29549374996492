.center {
  margin-inline: auto;
  max-width: var(--max-row-width);
}

@media only screen and (max-width: 112.5em) {
  .center {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
