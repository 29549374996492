.hd {
  padding-top: 3rem;
  padding-bottom: 8rem;
  /* height: calc(100vh - 10.4rem); */
}

.hd-wrapper {
  /* cursor: pointer; */
}

.hd-heading-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}
@media only screen and (max-width: 38.75em) {
  /* 620px */
  .hd-heading-head {
    gap: 1.5rem;
  }
}
@media only screen and (max-width: 33.13em) {
  /* 530px */
  .hd-heading-head {
    gap: 1rem;
  }
}
@media only screen and (max-width: 32.5em) {
  /* 520px */
  .hd-heading-head {
    flex-wrap: wrap;
  }
  .hd-end-text {
    flex-basis: 100%;
  }
}

.heading-char {
  transition: color 0.7s;
}

.heading-char:hover {
  /* color: var(--color-white); */
}

.heading-char::before {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-char);
  transform: translateY(-89%);
}

.hd-heading-divder {
  height: 3rem;
  background-color: var(--color-primary);
  transition: background-color 0.7s;
}
@media only screen and (max-width: 50em) {
  /* 800px */
  .hd-heading-divder {
    height: 1.5rem;
  }
}

.hd-heading-divder:hover {
  background-color: var(--color-white);
}

.hd-heading-down {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 3rem;
}

.hd-sub-heading-text {
  overflow: hidden;
  max-width: 125.2rem;
}
@media only screen and (max-width: 45.63em) {
  /* 730px */
  .hd-sub-heading-text {
    width: 100%;
  }
}

.hd-project-slider {
  /* flex-grow: 1; */
  height: 24rem;
  width: 33rem;
  background-color: transparent;
  border-radius: 2rem;
  margin-top: 2.6rem;
  opacity: 1;
  overflow: hidden;
  object-fit: cover;
}
@media only screen and (max-width: 62.5em) {
  /* 1000px */
  .hd-project-slider {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 50em) {
  /* 800px */
  .hd-project-slider {
    margin-top: 1.5rem;
  }
}
@media only screen and (max-width: 37.5em) {
  /* 600px */
  .hd-project-slider {
    display: none;
  }
}

.hd-project-img {
  object-fit: cover;
  border-radius: 2rem;
  width: 100%;
  filter: grayscale(1);
}
