.foo {
}

.foo-wrapper {
  padding-top: 9rem;
  padding-bottom: 8rem;
  /* background-color: red; */
}

.my-email {
  text-decoration: none;
  color: var(--color-primary);
  /* opacity: 1 !important; */
}

.my-email:hover {
  color: var(--color-white);
}

.socials {
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.social-link {
  padding: 1rem;
}
